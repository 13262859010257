<template>
  <div>
    <mqtt-service ref="mqttService"></mqtt-service>
    <LayoutDefault :layout="layout" :layoutMenu="layoutMenu" @onLogin="onLogin" @onReconnect="onReconnect">
      <router-view @updateLayout="updateLayout" v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </LayoutDefault>
  </div>
</template>
<script>
import LayoutDefault from './layouts/default/default';
import Vue from "vue";
import MqttService from "@/components/mqtt/mqtt-service.vue";
export default {
  name: 'Home-Page',
  components: {
    MqttService,
    LayoutDefault
  },
  data() {
    return {
      layout: false,
      layoutMenu: [],
      transitionName: 'slide',
      client: {
        connected: false,
      }
    }
  },
  mounted: function () {
    //Check if user is logged in
    if(localStorage.getItem('user') !== null){
      this.$store.commit('saveUserData', JSON.parse(localStorage.getItem('user')))
    }
    this.$root.$once('onLogin', this.onLogin)
    //this.createConnection()

    this.$http.interceptors.response.use((response) => {
          // Do something with response data
          if (response.data.success == false) {
            this.$buefy.notification.open({
              duration: 5000,
              message: 'Error: ' + response.data.error.message,
              position: 'is-bottom-right',
              type: 'is-danger',
              hasIcon: false
            })
          }
          return response;
    }, (error) => {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Error: Server error',
        position: 'is-bottom-right',
        type: 'is-danger',
        hasIcon: false
      })
      // whatever you want to do with the error
      throw error;
    });

  },
  beforeDestroy() {
  },
  methods: {
    /**
     * update layout (Disable (false) / Enable (true)
     * @param data
     */
    updateLayout(data) {
      this.layout = data;
    },
    onLogin(data){
      console.log('ON LOGIN CALLED', data)
      this.$refs.mqttService.createConnection(data)
    },
    onReconnect(dt){
      console.log('Call reconnect', dt.password)
      let user = this.$store.state.user.user;
      console.log('User', user)
      let data = {user: dt.email, pass: dt.password, id:user.id,
        organizationId: user.organization}

      console.log('Data', data)
      this.$refs.mqttService.createConnection(data)
    }
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
};
</script>

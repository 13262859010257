import Vue from "vue";

export default {
    getAllStatusModels: async ({commit}, $http) => {
        let config = {
            method: 'get',
            url: '/installer/v1/status_model/ALL'
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('saveStatusModels', response.data.statusModels)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
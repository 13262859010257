import Vue from "vue";
import qs from "qs";

export default {
    addTask: async ({commit}, props) => {
        let data = qs.stringify({
            'note': props.note,
            'dueDate': props.dueDate,
            'projectId': props.projectId,
            'contactPersonName': props.contactPersonName,
            'contactPersonPhone': props.contactPersonPhone,
            'locationName': props.locationName,
            'locationAddress': props.locationAddress,
            'longitude': props.longitude,
            'latitude': props.latitude
        });

        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/task',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        })
            .then((response) => {
                if(response.data.success){
                    commit('addTask', response.data.task)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateTask: async ({commit}, props) => {
        let data = qs.stringify({
            'note': props.note,
            'dueDate': props.dueDate,
            'projectId': props.projectId,
            'contactPersonName': props.contactPersonName,
            'contactPersonPhone': props.contactPersonPhone,
            'locationName': props.locationName,
            'locationAddress': props.locationAddress,
            'longitude': props.longitude,
            'latitude': props.latitude
        });

        return await Vue.prototype.$http({
            method: 'put',
            url: '/installer/v1/task/' + props.id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        })
            .then((response) => {
                if(response.data.success){
                    commit('updateTask', response.data.task)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    addDeviceTask: async ({commit}, props) => {
        let config = {
            method: 'post',
            url: '/installer/v1/task/device_task',
            headers: {
                'Content-Type': 'application/json'
            },
            data: props
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                if(response.data.success){
                    commit('updateTask', response.data.task)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    updateDeviceTask: async ({commit}, {props, taskId}) => {
        let config = {
            method: 'put',
            url: '/installer/v1/task/device_task/' + taskId,
            headers: {
                'Content-Type': 'application/json'
            },
            data: props
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                if(response.data.success){
                    commit('updateTask', response.data.task)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    listOfTasks: async ({commit}) => {
        return await Vue.prototype.$http({
            method: 'get',
            url: '/installer/v1/task',
        })
            .then((response) => {
                commit('saveTasks', response.data.tasks)
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    removeTask: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'delete',
            url: '/installer/v1/task',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "ids": [
                    data.id
                ]
            })
        }).then((response) => {
            if(response.data.success && response.data.deletedCount === 1){
                commit('deleteTask', data.id)
            }
            return response
        }).catch(function (error) {
            console.log(error);
        });
    },

    setTeamMembers: async ({commit}, props) => {
        let data = {
            'userIds': props.userIds,
        };

        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/task/add/team/set/' + props.taskId,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        })
            .then((response) => {
                if(response.data.success){
                    commit('updateTask', response.data.task)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addTaskStatus: async ({commit}, props) => {
        const data = new URLSearchParams();
        data.append('taskId', props.taskId);
        data.append('statusModelId', props.statusModelId);
        data.append('note', props.note);

        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/task/add/status',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        }).then((response) => {
            if (response.data.success) {
                commit('updateTask', response.data.task)
            }
            return response;
        }).catch(function (error) {
            console.log(error);
        });
    },
    copyToNewTask: async ({commit}, props) => {
        let data = qs.stringify({
            'taskId': props.taskId,
            'note': props.note,
            'dueDate': props.dueDate,
            'projectId': props.projectId,
            'bulkNote': props.bulkNote
        });

        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/task/create/task/copy',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        })
            .then((response) => {
                if (response.data.success) {
                    commit('addTask', response.data.task)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

}

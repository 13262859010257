import Vue from "vue";
import qs from 'qs'

export default {
    /**
     * Get all devices
     * @param commit
     * @returns {Promise<void>}
     */
    getAllDevices: async ({commit}) => {
        let config = {
            method: 'get',
            url: '/installer/v1/device'
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if(response.data.success) {
                    await commit('saveDevices', response.data.devices)
                }
                return response;
            })
            .catch(function (error) {
                // console.log(error);
            });
    },

    /**
     * Delete device
     * @param commit
     * @param device_id
     * @returns {Promise<void>}
     */
    deleteDevice: async ({commit}, device_id) => {
        // create data object with the id of the device to be deleted
        let data = JSON.stringify({
            "ids": [
                device_id
            ]
        });

        // create config object with necessary headers and data
        let config = {
            method: 'delete',
            url: '/installer/v1/device',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // send delete request using axios
        return await Vue.prototype.$http(config)
            .then((response) => {
                if(response.data.success && response.data.deletedCount === 1){
                    commit('deleteDevice', device_id)
                }
                return response;
            })
            .catch(function (error) {
                //console.log(error);
            });
    },

    /**
     * Create device
     * @param commit
     * @param data
     * @returns {Promise<*>}
     */
    createDevice: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/device',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then((response) => {
                if(response.data.success){
                    commit('addDevice', response.data.device)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    /**
     * Update device
     * @param commit
     * @param data
     * @returns {Promise<*>}
     */
    updateDevice: async ({commit}, data) => {
        // Send the HTTP request
        return await Vue.prototype.$http({
            method: 'PUT',
            url: `/installer/v1/device/${data.id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data.formData)
        })
            .then((response) => {
                if(response.data.success){
                    commit('updateDevice', response.data.device)
                }
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    /**
     * Get device by id
     * @param commit
     * @param data
     * @returns {Promise<*>}
     */
    addPropertyToDevice: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/device/params/property_group',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "deviceId": data.deviceId,
                "name": "About device",
                "priority": 0,
                "properties": data.properties
            })
        })
            .then((response) => {
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addPropertyGroupToDevice: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/device/params/property_group',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then((response) => {
                if(response.data.success){
                    commit('updateDevice', response.data.device)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    /**
     * Update device property
     * @param commit
     * @param data
     * @returns {Promise<*>}
     */
    updatePropertyDevice: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'PUT',
            url: '/installer/v1/device/params/property_group/property/' + data.deviceId,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                'deviceId': data.deviceId,
                'groupId': data.groupId,
                'propertyId': data.propertyId,
                'value': data.value
            })
        }).then(function (response) {
            return response;
        })
            .catch(function (error) {
                console.log(error);
            });
    },
    updatePropertyGroup: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'PUT',
            url: '/installer/v1/device/params/property_group/' + data.deviceId,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "name": data.name,
                "priority": data.priority,
                "properties": data.properties,
                "id":data.id
            })
        }).then(function (response) {
            if(response.data.success){
                commit('updateDevice', response.data.device)
            }
            return response;
        })
            .catch(function (error) {
                console.log(error);
            });
    },
    addImageToGallery: async ({commit}, config) =>{
        return await Vue.prototype.$http(config)
            .then(async(response) => {
                if(response.data.success){
                    //commit change
                    await commit('updateDevice', response.data.device)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteImageFromGallery: async ({commit}, payload) =>{

        let data = {ids:[payload.imageId]}

        let config = {
            method: 'delete',
            url: '/installer/v1/device/gallery/' + payload.deviceId,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then(async(response) => {
                if(response.data.success){
                    //commit change
                    await commit('removeFromDeviceGallery', {deviceId: payload.deviceId, imageId: payload.imageId})
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    downloadFile: async ({commit}, payload) =>{

        let data = {ids:payload.ids}

        let config = {
            method: 'post',
            url: '/installer/v1/device/gallery/download/zip/' + payload.deviceId,
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            data: data
        };

        return await Vue.prototype.$http(config)
            .then(async(response) => {
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

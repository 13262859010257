<template src="./template.html"></template>

<script>
/* eslint-disable */
import editProfile from '@/modules/core/edit-profile/edit-profile'
import { isJwtExpired } from 'jwt-check-expiration';
import Avatar from "@/components/avatar/avatar.vue";
import SubmitPassword from "@/components/submit-password/submit-password.vue";

export default {
  name: 'LayoutDefault',
  components:{
    SubmitPassword,
     editProfile,
    Avatar,
  },
  props: {
    layout: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      isLoading:false,
      isEditProfileActive:false,
      layoutMenu:[],
      isSubmitPassActive: false
    };
  },
  /**
   * Mounted
   */
  mounted:function(){

  },
  computed:{
    isMqttConnected(){
        return this.$store.state.mqttStatus === 'connected'
    }
  },
  methods: {
    /**
     * Logout Cognito
     */
    logout() {
      this.isLoading = true;
      let config = {
        method: 'get',
        url: '/installer/v1/user/logout'
      };

      this.$http(config)
          .then(function (response) {
            localStorage.removeItem('user');
            window.location.href = '/';
          })
          .catch(function (error) {
            localStorage.removeItem('user');
            window.location.href = '/';
          });

    },
    onSubmitPassword(data){
      if(data.password != null){
        this.isSubmitPassActive = false
        this.$emit('onReconnect', data)
      }
    }
  },
  watch: {
    /**
     * Get new many data when route is changed
     * @param to
     * @param from
     */
    $route(to, from) {
      console.log(to)
      let store =  this.$store.getters.getMenuLayout
      let path_cleaned = to.path.split('/')
      console.log(path_cleaned)
      let path = '';
      path_cleaned.forEach((item, index) => {
        if(item !== ''){
            if(item.split('-').length < 4){
              path = path + '/' + item
            }
        }
      })
      this.layoutMenu = store[path]

      let app = 'StallaZ'
      document.title = app+' - '+to.meta.title;

      console.log('Token is expore'+isJwtExpired(this.$store.state.user.token))
      if(isJwtExpired(this.$store.state.user.token)){
        this.logout()
      }

    }
  }
};
</script>

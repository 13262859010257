import Vue from "vue";

export default {
    getAllTemplates: async ({commit}) => {
        let config = {
            method: 'get',
            url: '/installer/v1/todo_template'
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('saveAllTemplates', response.data.templates)
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    addNewTemplate: async ({commit}, data) => {

        let config = {
            method: 'post',
            url: '/installer/v1/todo_template',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('addTemplate', response.data.template)
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    updateTemplate: async ({commit}, data) => {

        let payload = {
            name: data.name,
            todos: data.todos
        }

        let config = {
            method: 'put',
            url: '/installer/v1/todo_template/' + data.id,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('updateTemplate', response.data.template)
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    deleteTemplate: async ({commit}, id) => {

        let data = {ids:[id]}

        let config = {
            method: 'delete',
            url: '/installer/v1/todo_template',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                if(response.data.success && response.data.deletedCount === 1){
                    await commit('deleteTemplate', id)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
/**
 * Menu for every page on layout
 */
export default {
    '/project/list':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/project/add-edit':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/project/details':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device/list':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device/add-edit':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device/models/list':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device/models/view-model':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device/models/add-edit':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/task/list':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/task/add-edit':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/task/add-edit-task':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device-task/list':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device-task/add-edit':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/device/view-device':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/users':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ],
    '/documents':[
        {
            title: "Users",
            url: "/users",
            icon: "account-multiple"
        },
        {
            title: "Projects",
            url: "/project/list",
            icon: "clipboard-file"
        },
        {
            title: "Tasks",
            url: "/task/list",
            icon: "calendar-check"
        },
        {
            title: "Devices",
            url: "/device/list",
            icon:"cctv"
        },
        {
            title: "Device models",
            url: "/device/models/list",
            icon: "puzzle"
        },
        {
            title: "Documents",
            url: "/documents",
            icon:"file-document-multiple"
        }
    ]
}

//Vue & Vuex
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/default_store'
//Mapbox
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
//Axios
import qs from 'qs';
import Axios from 'axios'
//Buefy import
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
//SCSS Custom theme
import './main.scss'
import html2pdf from 'html2pdf.js'
import JsonCSV from 'vue-json-csv'

console.log('%c Real time traffic is the owner of this project, it is not safe to modify the content of the page.!', 'color: red; font-size: 30px; font-weight: bold;');

/**
 * Access to ENV file
 * @type {{readonly VUE_APP_API_URL?: string, readonly VUE_APP_MAPBOX_KEY?: string}}
 */
Vue.prototype.$env = process.env

Vue.prototype.$htmlToPdf = html2pdf

/**
 * Global HTTP
 * @type {AxiosStatic}
 */
Vue.prototype.$http = Axios
/**
 * Vue enable plugins
 */
Vue.use(VueMapbox, {mapboxgl: Mapbox});
/**
 * Vue enable plugins
 */
Vue.use(Buefy)

Vue.component('downloadCsv', JsonCSV)

/**
 * Interceptors
 * If user is loged in and API url start with RTT API url automatic add
 * JWT token
 */
Vue.prototype.$http.interceptors.request.use(
    (config) => {
        try {
            if (localStorage.getItem("user") !== null) {
                if (config.url === process.env.VUE_APP_API_URL + '/installer/v1/user/login') {
                    config.url = process.env.VUE_APP_API_URL + config.url
                    return config
                } else {
                    const isApiUrl = config.url.startsWith('/installer')
                    if (isApiUrl) {
                        config.headers['Authorization'] =
                            `Bearer ` + JSON.parse(localStorage.getItem('user')).token
                        config.url = process.env.VUE_APP_API_URL + config.url
                    }
                    return config
                }
            } else {
                config.url = process.env.VUE_APP_API_URL + config.url
                return config
            }
        } catch (e) {
            config.url = process.env.VUE_APP_API_URL + config.url
            return config
        }
    },
    (error) => {
        return Promise.reject(error)
    },
)

Vue.config.productionTip = true


/**
 * Vue start
 */
var _vm = new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount('#app')

import Vue from "vue";
export default {
    getAllDeviceModels: async ({commit}) => {
        let config = {
            method: 'get',
            url: '/installer/v1/device_model'
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('saveDeviceModels', response.data.deviceModels)
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    deleteDeviceModel: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'delete',
            url: '/installer/v1/device_model',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "ids": [
                    data.id
                ]
            })
        })
            .then((response) => {
                if(response.data.success && response.data.deletedCount === 1){
                    commit('deleteDeviceModel', data.id)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });

    },

    addDeviceModel: async ({commit}, payload) => {
        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/device_model',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        })
            .then((response) => {
                if(response.data.success){
                    commit('addDeviceModel', response.data.deviceModel)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    updateDeviceModel: async ({commit}, {payload, id}) => {
        return await Vue.prototype.$http({
            method: 'put',
            url: `/installer/v1/device_model/${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        })
            .then((response) => {
                if(response.data.success){
                    commit('updateDeviceModel', response.data.deviceModel)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

import Vue from "vue";

export default {
    getAllProjects: async ({commit}) => {
        await Vue.prototype.$http({
            method: 'get',
            url: '/installer/v1/project'
        }).then((response) => {
            commit('saveProjects', response.data.projects)
        }).catch(function (error) {
            console.log(error);
        });
    },
    deleteProject: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'delete',
            url: '/installer/v1/project',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "ids": [
                    data.id
                ]
            })
        })
            .then((response) => {
                if(response.data.success && response.data.deletedCount === 1){
                    commit('deleteProject', data.id)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addProject: async ({commit}, props) => {
        var data = new URLSearchParams();
        data.append('name', props.name)
        data.append('description', props.description)
        data.append('locationName', props.locationName)
        data.append('locationAddress', props.locationAddress)
        data.append('longitude', props.longitude)
        data.append('latitude', props.latitude)
        data.append('isActive', props.isActive)

        return await Vue.prototype.$http({
            method: 'post',
            url: '/installer/v1/project',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        }).then((response) => {
            if(response.data.success){
                commit('addProject', response.data.project)
            }
            return response
        })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateProject: async ({commit}, props) => {
        let data = new URLSearchParams();
        data.append('name', props.data.name)
        data.append('description', props.data.description)
        data.append('locationName', props.data.locationName)
        data.append('locationAddress', props.data.locationAddress)
        data.append('longitude', props.data.longitude)
        data.append('latitude', props.data.latitude)
        data.append('isActive', props.data.isActive)

        return await Vue.prototype.$http({
            method: 'put',
            url: '/installer/v1/project/' + props.id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        })
            .then((response) => {
                if(response.data.success){
                    commit('updateProject', response.data.project)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addProjectStatus: async ({commit}, props) => {
        const data = new URLSearchParams();
        data.append('statusModelId', props.statusModelId);
        data.append('note', props.note);

        return await Vue.prototype.$http({
            method: 'put',
            url: '/installer/v1/project/status/' + props.id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        }).then((response) => {
            if(response.data.success){
                commit('updateProject', response.data.project)
            }
            return response;
        }).catch(function (error) {
            console.log(error);
        });
    },
}

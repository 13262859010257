<template>
  <div>
    <section>
      <b-modal :active="isSubmitPassActive" @close="closeModal">
        <form action="">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Reconnect</p>
              <button
                  type="button"
                  class="delete"
                  @click="closeModal()"/>
            </header>
            <section class="modal-card-body">
              <b-field label="Email">
                <b-input
                    ref="email"
                    type="email"
                    v-model="email"
                    placeholder="Type email"
                    required>
                </b-input>
              </b-field>
              <b-field label="Password">
                <b-input
                    ref="pass"
                    type="password"
                    v-model="password"
                    validation-message="Min password length 10, 1 Upper character, 1 lower character,
                                    1 digit, 1 special character ! @ # $ & * ~"
                    pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{10,}$"
                    minlength="10"
                    password-reveal
                    placeholder="Type password to reconnect"
                    required>
                </b-input>
              </b-field>

            </section>
            <footer class="modal-card-foot">
              <b-button
                  label="Reconnect"
                  type="is-primary"
                  expanded
                  @click="connectMqtt()"/>
            </footer>
          </div>
        </form>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: "submit-password",
  props: {
    isSubmitPassActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      password: null,
      email:null
    }
  },
  methods: {
    /**
     * Close modal
     */
    closeModal() {
      this.$emit('onCloseModal')
    },
    async connectMqtt() {
      if(this.$refs.pass.isValid && this.password){
        this.$emit('onSubmitPassStatus', {password: this.password, email:this.email})
      }
    }
  }
}
</script>

<style scoped>

</style>
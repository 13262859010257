import Vue from "vue";
import Vuex from "vuex";

import mutations from "@/store/mutations";
import getters from "@/store/getters";

import menu from "@/router/menu"
Vue.use(Vuex);

import device from "@/store/actions/device";
import device_model from "@/store/actions/device_model";
import project from "@/store/actions/projects";
import task from "@/store/actions/tasks";
import toDo from "@/store/actions/to_do";
import status_model from "@/store/actions/status_model";
import users from "@/store/actions/users";
import todo_template from "@/store/actions/todo_templates";
import documents from "@/store/actions/documents";
import mqtt from "@/store/actions/mqtt";
export default new Vuex.Store({
    state: {
        //Menu for layout
        menu:menu,
        //User data from cognito
        user: null,
        projects: [],
        devices: [],
        deviceModels:[],
        tasks: [],
        statusModels: [],
        allUsers: [],
        todoTemplates: [],
        allDocuments:[],
        mqttStatus: 'unknown'
    },
    mutations: mutations,
    getters: getters,
    actions: {...device, ...device_model,...project,...task,...toDo, ...status_model,
        ...users, ...todo_template,...documents,...mqtt}
});

import Vue from "vue";
import qs from "qs";

export default {
    addToDo: async ({commit}, props) => {
        let data = qs.stringify({
            'deviceTaskId': props.deviceTaskId,
            'todoName': props.todoName,
            'todoDescription': props.todoDescription
        });

        return await Vue.prototype.$http({
            method: 'put',
            url: '/installer/v1/task/device_task/add/todo/' + props.taskId,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        }).then((response) => {
            if(response.data.success){
                commit('updateTask', response.data.task)
            }
            return response;
        }).catch(function (error) {
            console.log(error);
        });
    },
    removeToDo: async ({commit}, props) => {
        let data = qs.stringify({
            'deviceTaskId': props.deviceTaskId,
            'todoId': props.todoId
        });
        let config = {
            method: 'delete',
            url: '/installer/v1/task/device_task/remove/todo/' + props.fullTaskInfoId,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                if(response.data.success){
                    commit('updateTask', response.data.task)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    removeDeviceTask: async ({commit}, payload) =>{
        const data = new URLSearchParams();
        data.append('taskId', payload.taskId);
        data.append('deviceTaskId', payload.deviceTaskId);

        let config = {
            method: 'delete',
            url: '/installer/v1/task/device_task',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                if (response.data.success) {
                    commit('updateTask', response.data.task)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
}

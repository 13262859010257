<template src="./template.html"></template>

<script>
export default {
  name: 'edit-profile',
  props: {
    isEditProfileActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      newPassword: null,
      isLoading:false
    }
  },
  methods: {
    /**
     * Close modal
     */
    closeModal() {
      this.$emit('editProfileStatus', false)
    },
    async changePassword() {
      if(this.$refs.pass.isValid && this.newPassword){
        console.log('Pass is valid')
        this.isLoading = true
        let success = await this.$store.dispatch('changeUserPassword', this.newPassword)
        if(success){
          this.$buefy.toast.open({
            message: 'Password reset success!',
            type: 'is-success'
          })
          this.closeModal()
          this.$emit('onPasswordChanged');

        }
        this.isLoading = false

      }
    }
  }
}
</script>
<style lang="scss" scoped src="./style.scss"></style>

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    /**
     * Core
     */
    {
        path: '/',
        name: 'login',
        meta:{
            title: 'Login',
        },
        component: () => import('../modules/core/login/login.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta:{
            title: 'Register',
        },
        component: () => import('../modules/core/register/register.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-page',
        meta:{
            title: 'Forgot Password',
        },
        component: () => import('../modules/core/forgot/forgot.vue')
    },
    {
        path: '/activate',
        name: 'activate-account',
        meta:{
            title: 'Activate account',
        },
        component: () => import('../modules/core/activate-account/activate-account.vue')
    },
    {
        path: '/forgot',
        name: 'reset-password',
        meta:{
            title: 'Reset password',
        },
        component: () => import('../modules/core/reset-password/reset-password.vue')
    },
    /**
     * Project
     */
    {
        path: '/project/list',
        name: 'project',
        meta:{
            title: 'Projects',
        },
        component: () => import('../modules/projects/list/list.vue')
    },
    {
        path: '/project/add-edit/:id?',
        name: 'project-add-update',
        meta:{
            title: 'Projects',
        },
        props:true,
        component: () => import('../modules/projects/add-edit/add-edit.vue')
    },
    {
        path: '/project/details/:id',
        name: 'project-details',
        meta:{
            title: 'Project details',
        },
        props:true,
        component: () => import('../modules/projects/view/view_project.vue')
    },
    /**
     * Devices
     */
    {
        path: '/device/list',
        name: 'devices',
        meta:{
            title: 'Devices',
        },
        component: () => import('../modules/device/list/list.vue')
    },
    {
        path: '/device/add-edit/:id?',
        name: 'device-add-update',
        meta:{
            title: 'Device - Add/Edit',
        },
        props:true,
        component: () => import('../modules/device/add-edit/add-edit.vue')
    },
    {
        path: '/device/view-device/:deviceId',
        name: 'view-device',
        meta:{
            title: 'View device',
        },
        props:true,
        component: () => import('../modules/device/view/view-device.vue')
    },

    /**
     * Device models
     */
    {
        path: '/device/models/list',
        name: 'device-models',
        meta:{
            title: 'Device model',
        },
        component: () => import('../modules/device-models/list/list.vue')
    },
    {
        path: '/device/models/add-edit/:id?',
        name: 'device-models-list',
        meta:{
            title: 'Device model add edit',
        },
        props:true,
        component: () => import('../modules/device-models/add-edit/add-edit.vue')
    },
    {
        path: '/device/models/view-model/:id',
        name: 'device-models-view',
        meta:{
            title: 'View device model',
        },
        props:true,
        component: () => import('../modules/device-models/view/view-model.vue')
    },
    /**
     * Tasks
     */
    {
        path: '/task/list',
        name: 'tasks',
        meta:{
            title: 'Tasks',
        },
        component: () => import('../modules/tasks/list/list.vue')
    },
    {
        path: '/task/add-edit/:id?',
        name: 'task-add-update',
        meta:{
            title: 'Tasks - Add/Edit',
        },
        props:true,
        component: () => import('../modules/tasks/add-edit/add-edit.vue')
    },
    {
        path: '/device-task/add-edit/:id?/:devTaskId?',
        name: 'device-task-add-update',
        meta:{
            title: 'Tasks - Add/Edit',
        },
        props:true,
        component: () => import('../modules/device-tasks/add-edit/add-edit.vue')
    },
    /**
     * Todo
     */
    {
        path: '/device-task/list/:task_id?',
        name: 'device-tasks',
        meta:{
            title: 'Project tasks - Add/Edit',
        },
        props:true,
        component: () => import('../modules/device-tasks/list/device-tasks.vue')
    },
    /**
     * Users
     */
    {
        path: '/users',
        name: 'user-management',
        meta:{
            title: 'Users',
        },
        props:true,
        component: () => import('../modules/user/user-management.vue')
    },

    /**
     * Documents
     */
    {
        path: '/documents',
        name: 'app-documents',
        meta:{
            title: 'Documents',
        },
        props:true,
        component: () => import('../modules/document/app-documents.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})
/**
 * Auth check if user is loged in
 */
router.beforeEach(async (to, from, next) => {
    //Disable check just for these routes
    if (to.name === 'login' || to.name === 'forgot-page' || to.name === 'activate-account' || to.name === 'reset-password') {
        //NOT Authorized
        next()
    } else {
        if ('user' in localStorage) {
            next();
        } else {
            next({name: 'login'})
        }

    }
})
export default router

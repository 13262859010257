export default {
    saveUserData: (state, payload) => {
        localStorage.setItem('user', JSON.stringify(payload))
        state.user = payload
    },
    saveProjects: (state, payload) => {
        state.projects = payload
    },
    addProject: (state, payload) => {
        state.projects.push(payload)
    },
    updateProject: (state, payload) =>{
        let index = state.projects.findIndex(project => project.id === payload.id)
        if(index !== -1){
            state.projects.splice(index, 1, payload)
        }
    },
    deleteProject: (state, id) =>{
        let index = state.projects.findIndex(project=>project.id === id)
        if(index !== -1){
            state.projects.splice(index, 1)
        }
    },
    saveDevices: (state, payload) => {
        state.devices = payload
    },
    deleteDevice: (state, id) =>{
        let index = state.devices.findIndex(device=>device.id === id)
        if(index !== -1){
            state.devices.splice(index, 1)
        }
    },
    addDevice: (state, payload) =>{
        state.devices.push(payload)
    },
    saveDevicesByType: (state, payload) => {
        state.devicesByType = payload
    },
    updateDevice: (state, payload) =>{
        let index = state.devices.findIndex(device => device.id === payload.id)
        if(index !== -1){
            state.devices.splice(index, 1, payload)
        }
    },
    removeFromDeviceGallery: (state, payload) =>{
        let device = state.devices.find(device => device.id === payload.deviceId)
        if(device){
            let index = device.gallery.findIndex(image => image.id === payload.imageId)
            if(index !== -1){
                device.gallery.splice(index, 1)
            }
        }
    },
    saveDeviceModels: (state, payload) => {
        state.deviceModels = payload
    },
    addDeviceModel: (state, payload) => {
        state.deviceModels.push(payload);
    },
    deleteDeviceModel: (state, id) =>{
        let index = state.deviceModels.findIndex(dm=>dm.id === id)
        if(index !== -1){
            state.deviceModels.splice(index, 1)
        }
    },
    updateDeviceModel: (state, payload) =>{
        let index = state.deviceModels.findIndex(dm => dm.id === payload.id)
        if(index !== -1){
            state.deviceModels.splice(index, 1, payload)
        }
    },
    saveTasks: (state, payload) => {
        state.tasks = payload
    },
    updateTask: (state, payload) =>{
        let index = state.tasks.findIndex(task => task.id === payload.id)
        if(index !== -1){
            state.tasks.splice(index, 1, payload)
        }
    },
    addTask: (state, payload) =>{
        state.tasks.push(payload)
    },
    deleteTask: (state, id) =>{
        let index = state.tasks.findIndex(task=>task.id === id)
        if(index !== -1){
            state.tasks.splice(index, 1)
        }
    },
    saveStatusModels: (state, payload) => {
        state.statusModels = payload
    },
    saveAllUsers: (state, payload) =>{
        state.allUsers = payload
    },
    addUser: (state, payload) =>{
        state.allUsers.push(payload)
    },
    removeUser: (state, id) =>{
        let index = state.allUsers.findIndex(user=>user.id === id)
        if(index !== -1){
            state.allUsers.splice(index, 1)
        }
    },
    saveAllTemplates: (state, payload) =>{
        state.todoTemplates = payload
    },
    addTemplate: (state, payload) =>{
        state.todoTemplates.push(payload)
    },
    updateTemplate: (state, payload) =>{
        let template = state.todoTemplates.find(template=>template.id === payload.id)
        template.name = payload.name;
        template.todos = payload.todos.filter(todo => todo.name != null)
    },
    deleteTemplate: (state, id) =>{
        let index = state.todoTemplates.findIndex(template=>template.id === id)
        if(index !== -1){
            state.todoTemplates.splice(index, 1)
        }
    },
    saveAllDocuments: (state, payload) =>{
        state.allDocuments = payload
    },
    addDocument: (state, payload) =>{
        state.allDocuments.push(payload)
    },
    updateDocument: (state, payload) =>{
        let index = state.allDocuments.findIndex(doc => doc.id === payload.id)
        if(index !== -1){
            state.allDocuments.splice(index, 1, payload)
        }
    },
    removeDocument: (state, id) =>{
        let index = state.allDocuments.findIndex(doc => doc.id === id)
        if(index !== -1){
            state.allDocuments.splice(index, 1)
        }
    },
    removeDocumentNote: (state, payload) =>{
        let document = state.allDocuments.find(doc => doc.id === payload.id)
        if(document !== null){
            let noteIndex = document.notes.findIndex(note => note.id === payload.noteId)
            if(noteIndex !== -1){
                document.notes.splice(noteIndex, 1)
            }
        }
    },
    setMqttStatus:(state, status) =>{
        state.mqttStatus = status
    }
};

export default {
    isAuth: (state) => {
        return state.user !== null
    },
    getMenuLayout: (state, path) => {
        return state.menu
    },

    getProjectsByStatus: (state) => (status) => {
        return state.projects.filter(project => status.includes(project.lastStatus.statusModel.name))
    },

    getProject: (state) => (id) => {
        return state.projects.find(project => project.id === id)
    },

    getTask: (state) => (id) => {
        return state.tasks.find(task => task.id === id)
    },

    getTableTasks: (state) => (status) => {
        let tableTasks = [];

        state.tasks.forEach(task => {

            if (status === 'ALL' || status.includes(task.lastTaskStatus.statusModel.name)) {
                let location = '<p><span class="has-text-weight-medium">Name:</span> '
                if (task.locationName != null) {
                    location += task.locationName
                }

                location += '</p><p><span class="has-text-weight-medium">Address:</span> '
                if (task.locationAddress != null) {
                    location += task.locationAddress
                }
                location += '</p>'

                let tableItem = {
                    id: task.id,
                    status: task.lastTaskStatus,
                    teamMembers: task.teamMembers,
                    note: task.note,
                    project: task.project,
                    location: location,
                    numOfDeviceTasks: task.deviceTasks !== null ? task.deviceTasks.length : 0
                }

                tableTasks.push(tableItem)
            }
        })


        return tableTasks
    },

    getTasksForProject: (state) => (projectId) => {
        return state.tasks.filter(task => task.project.id === projectId);
    },

    getDeviceModel: (state) => (id) => {
        return state.deviceModels.find(model => model.id === id)
    },

    getDevice: (state) => (id) => {
        return state.devices.find(device => device.id === id)
    },

    getTableDevices: (state) => (type) => {
        let devices = state.devices.filter(device => device.deviceType === type)
        devices.forEach(device => {
            let propertyStr = '';
            device.deviceModel.deviceProperties.properties.forEach(property => {
                if (property.value.includes('http')) {
                    propertyStr += '<p>' + property.name + ' : ' + '<a href="' + property.value + '">' + 'View ->' + '</a>' + '<p/>';
                } else {
                    propertyStr += '<p>' + property.name + ' : ' + property.value + '<p/>';
                }
            });
            device.modelData = propertyStr;
            device.locationData = '<p style="white-space: normal"><span class="has-text-weight-semibold">Name: </span>'
                + device.locationName + '</p>' + '<span class="has-text-weight-semibold">Address: </span>'
                + device.locationAddress;
        })

        return devices
    },

    getDeviceModelsByName: (state) => (name) => {
        let newName = name.toLowerCase().replace("_", "");
        console.log('New name: ' + newName);
        let models = state.deviceModels.filter(model => model.name.toLowerCase().includes(newName));
        if (models != null && models.length > 0) {
            return models;
        } else {
            newName = name.toLowerCase().replace("_", " ");
            models = state.deviceModels.filter(model => model.name.toLowerCase().includes(newName));
            return models;
        }
    },

    getDeviceTask: (state) => (taskId, deviceTaskId) => {
        let task = state.tasks.find(task => task.id === taskId);
        if (task != null && task.deviceTasks != null) {
            return task.deviceTasks.find(devTask => devTask.id === deviceTaskId);
        }
    },

    getUsersByRole: (state) => (roleId) => {
        if (roleId === null) {
            return state.allUsers
        } else {
            return state.allUsers.filter(user => user.roleId === roleId)
        }
    },

    getUserById: (state) => (id) => {
        return state.allUsers.find(user => user.id === id)
    },

    getDocumentsTable: (state) => {
        let tableData = [];
        state.allDocuments.forEach(doc => {
            let signedBy = doc.signedBy.filter(user => user.signatureDate !== 0);
            let tableDoc = {
                id: doc.id,
                name: doc.name,
                description: doc.description,
                project: doc.project != null ? doc.project.name : 'N/A',
                documentPath: doc.documentPath,
                createdTime: new Date(doc.createdTime).toLocaleString(),
                allUsers: doc.signedBy,
                isSigned: signedBy.length > 0
            }
            tableData.push(tableDoc)
        })

        return tableData
    },
    getStatusModelsByType: (state) => (type) => {
        console.log('Status types:', state.statusModels)
        return state.statusModels.filter(model => model.statusType === type)
    },


}

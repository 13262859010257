import Vue from "vue";

export default {
    getAllUsers: async ({commit}) => {
        let config = {
            method: 'get',
            url: '/installer/v1/organization_user/users/ALL'
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('saveAllUsers', response.data.users)
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addUser: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: '/installer/v1/organization_user',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                if(response.data.success) {
                    await commit('addUser', response.data.user)
                    return true
                }else{
                    return false
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteUser: async ({commit}, userId) => {
        let payload = {ids:[userId]}
        let config = {
            method: 'delete',
            url: '/installer/v1/organization_user',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                if(response.data.success && response.data.deletedCount === 1) {
                    await commit('removeUser', userId)
                    return true
                }else{
                    return false
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    changeUserPassword: async ({commit}, newPassword) => {
        const data = new URLSearchParams();
        data.append('newPass', newPassword);

        let config = {
            method: 'post',
            url: '/installer/v1/user/change/password/me',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                if(response.data.success) {
                    return true
                }else{
                    return false
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

}
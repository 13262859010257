<template>
  <div></div>
</template>

<script>
import mqtt from 'mqtt/dist/mqtt';

export default {
  name: "mqtt-service",
  mounted() {
    this.client = {
      connected: false,
    }
    this.organizationId = null
    this.topics = []
  },
  methods: {
    createConnection(data, url) {
      console.log('Create connection called')
      if (this.$store.state.mqttStatus !== 'connected') {
        this.$store.dispatch('setMqttStatus', 'connecting')
        try {
          this.client = mqtt.connect(this.$env.VUE_APP_MQTT_URL, {
            clientId: "stlz_web_" + Math.random().toString(16).substring(2, 8),
            username: data.user,
            password: data.pass,
          })
          this.organizationId = data.organizationId;

          this.topics = [
            {name: 'devices', topicName: 'topic/devices/', topic: 'topic/devices/' + this.organizationId, qos: 0},
            {name: 'tasks', topicName: 'topic/tasks/',topic: 'topic/tasks/' + this.organizationId, qos: 0},
            {name: 'projects', topicName:'topic/projects/',topic: 'topic/projects/' + this.organizationId, qos: 0},
            {name: 'documents', topicName:'topic/documents/',topic: 'topic/documents/' + this.organizationId, qos: 0},
            {name: 'deleted', topicName:'topic/deleted/',topic: 'topic/deleted/' + this.organizationId, qos: 0},
          ]

        } catch (error) {
          this.$store.dispatch('setMqttStatus', 'disconnected')
          console.log('mqtt.connect error', error)
        }
        this.client.on('connect', () => {
          console.log('Connection succeeded!')
          this.$store.dispatch('setMqttStatus', 'connected')
          if (this.topics.length > 0) {
            this.topics.forEach(topic => {
              this.doSubscribe(topic)
            })
          }

        })
        this.client.on('error', error => {
          this.$store.dispatch('setMqttStatus', 'disconnected')
          console.log('Connection failed', error)
        })
        this.client.on('message', async (topic, message) => {
          let receivedTopic = this.topics.find(t => topic.includes(t.topicName))
          console.log('Received topic', topic)
          if (receivedTopic !== undefined) {
            let objectUpdate = JSON.parse(message)
            console.log('Update object', objectUpdate)
            if (objectUpdate != null && objectUpdate.changedById !== this.$store.state.user.user.id) {
              let msg = ''
              if (receivedTopic.name === 'devices') {
                if (this.$store.state.devices.find(device => device.id === objectUpdate.device.id) !== undefined) {
                  this.$store.commit('updateDevice', objectUpdate.device)
                  msg = 'Device ' + objectUpdate.device.deviceType + ' updated externally'
                } else {
                  this.$store.commit('addDevice', objectUpdate.device)
                  msg = 'Device ' + objectUpdate.device.deviceType + ' created externally'
                }
              } else if (receivedTopic.name === 'projects') {
                if (this.$store.state.projects.find(pr => pr.id === objectUpdate.project.id) !== undefined) {
                  this.$store.commit('updateProject', objectUpdate.project)
                  msg = 'Project ' + objectUpdate.project.name + ' updated externally'
                } else {
                  this.$store.commit('addProject', objectUpdate.project)
                  msg = 'Project ' + objectUpdate.project.name + ' created externally'
                }
              } else if (receivedTopic.name === 'tasks') {
                if (this.$store.state.tasks.find(task => task.id === objectUpdate.task.id) !== undefined) {
                  this.$store.commit('updateTask', objectUpdate.task)
                  msg = 'Task ' + objectUpdate.task.note + ' updated externally'
                } else {
                  this.$store.commit('addTask', objectUpdate.task)
                  msg = 'Task ' + objectUpdate.task.note + ' created externally'
                }
              } else if (receivedTopic.name === 'documents') {
                if (this.$store.state.allDocuments.find(doc => doc.id === objectUpdate.document.id) !== undefined) {
                  this.$store.commit('updateDocument', objectUpdate.document)
                  msg = 'Document ' + objectUpdate.document.name + ' updated externally'
                } else {
                  this.$store.commit('addDocument', objectUpdate.document)
                  msg = 'Document ' + objectUpdate.document.name + ' updated externally'
                }
              } else if (receivedTopic.name === 'deleted' && objectUpdate.ids != null && objectUpdate.ids.length !== 0) {
                if (objectUpdate.deletedObject === 'Project') {
                  this.$store.commit('deleteProject', objectUpdate.ids[0])
                  msg = 'Project deleted externally'
                } else if (objectUpdate.deletedObject === 'Device') {
                  this.$store.commit('deleteDevice', objectUpdate.ids[0])
                  msg = 'Device deleted externally'
                } else if (objectUpdate.deletedObject === 'Task') {
                  this.$store.commit('deleteTask', objectUpdate.ids[0])
                  msg = 'Task deleted externally'
                } else if (objectUpdate.deletedObject === 'Document') {
                  this.$store.commit('removeDocument', objectUpdate.ids[0])
                  msg = 'Document deleted externally'
                }
              }

                this.$buefy.notification.open({
                  message: msg,
                  duration: 5000,
                  progressBar: true,
                  hasIcon: true,
                  type: 'is-primary',
                  pauseOnHover: true
                })
              }
          }
        })
      }
    },
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end()
          this.client = {
            connected: false,
          }
          this.$store.dispatch('setMqttStatus', 'disconnected')
          console.log('Successfully disconnected!')
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      } else {
        this.$store.dispatch('setMqttStatus', 'disconnected')
      }
    },
    doUnSubscribe(tpc) {
      if (this.client.connected) {
        const {topic} = tpc
        this.client.unsubscribe(topic, error => {
          if (error) {
            console.log('Unsubscribe error', error)
          }
        })
      }
    },
    doSubscribe(top) {
      if (this.client.connected) {
        const {topic, qos} = top
        this.client.subscribe(topic, {qos}, (error, res) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          console.log('Subscribe to topics res', res)
        })
      }
    }
  },
  beforeDestroy() {
    this.topics.forEach(topic => {
      this.doUnSubscribe(topic)
    })
    this.destroyConnection()
  }
}
</script>

<style scoped>

</style>
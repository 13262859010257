import Vue from "vue";

export default {
    getAllDocuments: async ({commit}) => {
        let config = {
            method: 'get',
            url: '/installer/v1/document'
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                await commit('saveAllDocuments', response.data.documents)
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    addNewDocument: async ({commit}, data) => {

        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('projectId', data.projectId);
        data.needSignatureFromIds.forEach(id=>{
            formData.append('needSignatureFromIds', id);
        })

        let config = {
            method: 'post',
            url: '/installer/v1/document',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                if(response.data.success) {
                    await commit('addDocument', response.data.document)
                }

                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateDocument: async ({commit}, data) => {


        let formData = new FormData();
        if(data.doc.file != null) {
            formData.append('file', data.doc.file);
        }
        formData.append('name', data.doc.name);
        formData.append('description', data.doc.description);
        formData.append('projectId', data.doc.projectId);
        data.doc.needSignatureFromIds.forEach(id=>{
            formData.append('needSignatureFromIds', id);
        })

        let config = {
            method: 'put',
            url: '/installer/v1/document/' + data.id,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        };

        return await Vue.prototype.$http(config)
            .then(async (response)=>{
                if(response.data.success) {
                    await commit('updateDocument', response.data.document)
                }

                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteDocument: async ({commit}, id) =>{

        let data = {ids:[id]}

        let config = {
            method: 'delete',
            url: '/installer/v1/document',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then(async(response) => {
                if(response.data.success){
                    //commit change
                    await commit('removeDocument', id)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    deleteDocumentNote: async ({commit}, data) =>{

        let payload = {ids:[data.noteId]}

        let config = {
            method: 'delete',
            url: '/installer/v1/document/note/' + data.id,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };

        return await Vue.prototype.$http(config)
            .then(async(response) => {
                if(response.data.success && response.data.deletedCount === 1){
                    //commit change
                    await commit('removeDocumentNote', data)
                }
                return response
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}